import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage = 1, mySubpage = 4 }) => {
  const questionNumber = 2
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"ONE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Which of the following is not a protective factor?"}
        answers={[
          "High expectations",
          "Positive peer relationships",
          "Caring Adult Relationships",
          "Self-efficacy",
        ]}
        correctAnswerNumber={0}
        answerDetail={
          "A protective factor is something that helps build a child’s ability to be resilient when facing challenges in life. Self-efficacy, caring adult relationships, and positive peer relationships help this develop in young people."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 1, 4)
